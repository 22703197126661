/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SkillMapCreationRequest = {
  properties: {
    title: {
      type: 'string',
      isRequired: true,
    },
    activate: {
      type: 'boolean',
      isRequired: true,
    },
    professionId: {
      type: 'string',
      isRequired: true,
    },
    specialtyId: {
      type: 'string',
      isRequired: true,
      isNullable: true,
    },
    registrationType: {
      type: 'SkillMapRegistrationType',
      isRequired: true,
    },
    skills: {
      type: 'array',
      contains: {
        type: 'Skill',
      },
      isRequired: true,
    },
    isDefault: {
      type: 'boolean',
      isRequired: true,
    },
  },
} as const;
