/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdminListSkillMapFilters = {
  properties: {
    mapId: {
      type: 'string',
    },
    title: {
      type: 'string',
    },
    status: {
      type: 'SkillMapStatuses',
    },
    prodfessionId: {
      type: 'string',
    },
    specialtyId: {
      type: 'string',
    },
    registrationType: {
      type: 'SkillMapRegistrationType',
    },
    isDefault: {
      type: 'boolean',
    },
  },
} as const;
